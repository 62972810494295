import * as React from 'react';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

type Data = {
  xeroConnection: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const IntegrationInfo: React.FC = () => {
  const { xeroConnection } = useStaticQuery<Data>(
    graphql`
      {
        xeroConnection: file(relativePath: { eq: "xero-connection-flow.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          }
        }
      }
    `,
  );

  return (
    <Flex direction="column" as="section" align="center" bgColor="#F2F4F8">
      <Heading px={4} pt={{ base: '40px', md: '80px' }} textAlign="center">
        More about how Canyou and Xero integrate
      </Heading>
      <Flex
        justifyContent="center"
        direction={{ base: 'column', md: 'row' }}
        m={4}
        alignItems="center"
      >
        <Flex direction="column" alignItems="center">
          <Text maxWidth="500px" m={6}>
            Syncing data is effortless and realtime, drive your business and
            always be in control of your business data.
          </Text>
          <Button
            as="a"
            href="/support/integrations/xero/setup"
            target="_blank"
            rel="noreferrer noopener"
            size="lg"
            maxWidth="310px"
          >
            Learn how to connect to Xero
          </Button>
        </Flex>
        <GatsbyImage
          style={{
            width: `400px`,
          }}
          alt="xeroConnection"
          image={xeroConnection.childImageSharp.gatsbyImageData}
        />
      </Flex>
    </Flex>
  );
};

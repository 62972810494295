import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import {
  Button,
  Flex,
  Heading,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { Explainer } from '../../components/pages/home/Explainer';
import { Layout } from '../../components/Layout';
import { SEO } from '../../components/SEO';
import { ContactUs } from '../../components/pages/home/ContactUs';
import { AboutXero } from '../../components/pages/xero/AboutXero';
import { HowItWorks } from '../../components/pages/xero/HowItWorks';
import { IntegrationInfo } from '../../components/pages/xero/IntegrationInfo';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { getConfig } from '../../config';
import { PricingSection } from '../../components/PricingSection';

type Data = {
  heroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  deviceImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  xeroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const config = getConfig();
const signupUrl = `${config.platformUrl}/signup`;

const Home: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed Xero Marketing Page');
  }, [mixpanel]);

  const { heroImage, deviceImage, xeroImage } = useStaticQuery<Data>(
    graphql`
      {
        heroImage: file(relativePath: { eq: "hero-background.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        deviceImage: file(relativePath: { eq: "hero-device-xero.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
        xeroImage: file(relativePath: { eq: "xero-connected.png" }) {
          childImageSharp {
            gatsbyImageData(width: 980, layout: CONSTRAINED)
          }
        }
      }
    `,
  );
  const backgroundHeight = useBreakpointValue({ base: '780px', md: '100vh' });

  const bgImage = convertToBgImage(heroImage.childImageSharp.gatsbyImageData);

  return (
    <Layout>
      <SEO
        title="Xero Integration with Canyou streamlines your business processes"
        description="Integrate with Xero and Canyou to easily synchronise employee data. Streamline and optimize employee onboarding. Ensure business compliance."
      />
      <BackgroundImage
        Tag="section"
        style={{
          width: '100%',
          height: backgroundHeight,
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        fadeIn
        {...bgImage}
      >
        <Flex direction="column" align="center" justify="center">
          <Heading
            as="h1"
            fontSize={{ base: '36px', md: '56px' }}
            pt={{ base: '40px', lg: '50px', xl: '70px' }}
            textAlign="center"
            lineHeight="lg"
            fontWeight="bold"
          >
            Integrate Canyou with Xero
          </Heading>
          <Heading
            p={{ base: 5, lg: 7, xl: 9 }}
            size="md"
            textAlign="center"
            display={{ base: 'none', md: 'block' }}
          >
            Employee compliance made easy with{' '}
            <Box as="span" color="brand.blue">
              Canyou{' '}
            </Box>
            and Xero
          </Heading>
          <Flex
            maxWidth={{
              base: '100px',
              md: '200px',
            }}
          >
            <GatsbyImage
              alt="xero"
              image={xeroImage.childImageSharp.gatsbyImageData}
              style={{
                margin: '0 auto 1.45rem',
              }}
            />
          </Flex>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Button
              size="lg"
              mb={{ base: 2, lg: 8 }}
              mx={2}
              as="a"
              minWidth="303"
              href={signupUrl}
              target="_blank"
              rel="noreferrer noopener"
              onClick={(): void => {
                mixpanel.track('Xero page: clicked "start trial"');
              }}
            >
              Try for free
            </Button>
            <Button
              size="lg"
              as="a"
              href="https://www.xero.com/signup/?xtid=x30zapworkflows"
              mb={{ base: 2, lg: 8 }}
              mx={2}
              target="_blank"
              rel="noreferrer noopener"
              minWidth="303"
              variant="outline"
              onClick={(): void => {
                mixpanel.track('Xero page: clicked "try Xero"');
              }}
            >
              Try Xero for Free
            </Button>
          </Flex>
        </Flex>
        <Flex direction="column" as="section" align="center">
          <GatsbyImage
            alt="device"
            image={deviceImage.childImageSharp.gatsbyImageData}
            style={{
              maxWidth: `680px`,
              margin: '0 auto 1.45rem',
            }}
          />
        </Flex>
      </BackgroundImage>
      <Explainer />
      <HowItWorks />
      <AboutXero />
      <IntegrationInfo />
      <PricingSection />
      <ContactUs
        headingText="Still have questions?"
        bodyText="Send us a message and we will get in touch"
      />
    </Layout>
  );
};

export default Home;

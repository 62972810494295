import * as React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

type Data = {
  tasksImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  documentImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  notificationImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const Explainer: React.FC = () => {
  const { tasksImage, documentImage, notificationImage } = useStaticQuery<Data>(
    graphql`
      {
        tasksImage: file(relativePath: { eq: "tasks.png" }) {
          childImageSharp {
            gatsbyImageData(width: 350, layout: CONSTRAINED)
          }
        }
        documentImage: file(relativePath: { eq: "document-storage.png" }) {
          childImageSharp {
            gatsbyImageData(width: 350, layout: CONSTRAINED)
          }
        }
        notificationImage: file(relativePath: { eq: "notification.png" }) {
          childImageSharp {
            gatsbyImageData(width: 350, layout: CONSTRAINED)
          }
        }
      }
    `,
  );
  return (
    <Flex direction="column" as="section" align="center">
      <Heading
        size="md"
        pt={{ base: '40px', md: '80px' }}
        textAlign="center"
        px={4}
      >
        Automate, manage, and communicate business <br />
        tasks through workflows
      </Heading>
      <Heading
        fontSize="1.8em"
        fontFamily="roboto"
        fontWeight="400"
        py={10}
        textAlign="center"
        px={6}
        maxWidth="1100px"
      >
        Use our workflow builder to map out and customise your unique business
        processes
      </Heading>
      <Flex justifyContent="center" wrap="wrap" mb={6}>
        <div
          style={{
            maxWidth: `300px`,
            marginBottom: `1.45rem`,
            minWidth: '250px',
          }}
        >
          <GatsbyImage
            image={tasksImage.childImageSharp.gatsbyImageData}
            alt="tasks"
          />
        </div>
        <Flex maxWidth="670px" direction="column" justify="center" p={5}>
          <Heading size="md" mb={4} textAlign={{ base: 'center', md: 'start' }}>
            Automatically assign and complete tasks
          </Heading>
          <Text fontSize="lg" textAlign={{ base: 'center', md: 'start' }}>
            Build workflows to handle your business processes such as hiring,
            training and certifying your staff members. Canyou makes it easy.
          </Text>
        </Flex>
      </Flex>
      <Flex
        justifyContent="center"
        wrap="wrap"
        mb={6}
        direction={{ base: 'row', md: 'row-reverse' }}
      >
        <div
          style={{
            maxWidth: `300px`,
            marginBottom: `1.45rem`,
            minWidth: '250px',
          }}
        >
          <GatsbyImage
            image={documentImage.childImageSharp.gatsbyImageData}
            alt="documents"
          />
        </div>
        <Flex maxWidth="670px" direction="column" justify="center" p={5}>
          <Heading size="md" mb={4} textAlign={{ base: 'center', md: 'start' }}>
            Your central place to store and manage business documents
          </Heading>
          <Text fontSize="lg" textAlign={{ base: 'center', md: 'start' }}>
            Store and share your contracts, training documents, licenses with
            your employees and sub-contractors at ease. Store all your business
            compliance documents safely and securely online.
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="center" wrap="wrap">
        <div
          style={{
            maxWidth: `300px`,
            marginBottom: `1.45rem`,
            marginTop: `1.45rem`,
            minWidth: '250px',
          }}
        >
          <GatsbyImage
            alt="notification"
            image={notificationImage.childImageSharp.gatsbyImageData}
          />
        </div>
        <Flex maxWidth="670px" direction="column" justify="center" p={5}>
          <Heading size="md" mb={4} textAlign={{ base: 'center', md: 'start' }}>
            Keep everyone on the same page with real time status on progression
          </Heading>
          <Text fontSize="lg" textAlign={{ base: 'center', md: 'start' }}>
            Keep your team on the same page with a real time status of your
            business processes so you spend less time wrangling the paperwork
            loop, chasing pieces of paper and saving the same information in
            multiple places.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

import * as React from 'react';
import {
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

export const AboutXero: React.FC = () => {
  return (
    <Flex direction="column" as="section" align="center" pb={6}>
      <Heading pt={{ base: '40px', md: '80px' }} textAlign="center" px={4}>
        About Xero
      </Heading>
      <Text fontSize="lg" textAlign="center" m={5}>
        Xero is world-leading online accounting software built for small
        business.
      </Text>
      <Flex
        justifyContent="center"
        direction={{ base: 'column', md: 'row' }}
        mb={6}
      >
        <Flex maxWidth="500px" mx={6}>
          <UnorderedList spacing={6}>
            <ListItem>
              Get a real-time view of your cashflow. Log in anytime, anywhere on
              your Mac, PC, tablet or phone to get a real-time view of your cash
              flow. It’s small business accounting software that’s simple, smart
              and occasionally magical.
            </ListItem>
            <ListItem>
              Run your business on the go. Use our mobile app to reconcile, send
              invoices, or create expense claims - from anywhere.
            </ListItem>
          </UnorderedList>
        </Flex>
        <Flex maxWidth="500px" mx={6}>
          <UnorderedList spacing={6}>
            <ListItem>
              Get paid faster with online invoicing. Send online invoices to
              your customers - and get updated when they’re opened.
            </ListItem>
            <ListItem>
              Reconcile in seconds. Xero imports and categorises your latest
              bank transactions. Just click ok to reconcile.
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
      <Text fontSize="lg" textAlign="center">
        Find out more or try{' '}
        <Link
          href="https://www.xero.com/"
          hrefLang="en"
          media="all"
          textDecoration="underline"
        >
          Xero Accounting Software
        </Link>{' '}
        for free.
      </Text>
    </Flex>
  );
};

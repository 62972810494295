import * as React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

type Data = {
  step1: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  step2: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  step3: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  step4: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const HowItWorks: React.FC = () => {
  const { step1, step2, step3, step4 } = useStaticQuery<Data>(
    graphql`
      {
        step1: file(relativePath: { eq: "step-1.png" }) {
          childImageSharp {
            gatsbyImageData(width: 200, layout: FIXED)
          }
        }
        step2: file(relativePath: { eq: "step-2.png" }) {
          childImageSharp {
            gatsbyImageData(width: 200, layout: FIXED)
          }
        }
        step3: file(relativePath: { eq: "step-3.png" }) {
          childImageSharp {
            gatsbyImageData(width: 200, layout: FIXED)
          }
        }
        step4: file(relativePath: { eq: "step-4.png" }) {
          childImageSharp {
            gatsbyImageData(width: 200, layout: FIXED)
          }
        }
      }
    `,
  );
  const imageStyles = {
    marginBottom: `1.45rem`,
    marginTop: `1.45rem`,
  };
  return (
    <Flex direction="column" as="section" align="center" bgColor="#F2F4F8">
      <Heading
        size="lg"
        pt={{ base: '40px', md: '80px' }}
        textAlign="center"
        fontWeight="bold"
        px={4}
      >
        Why use Canyou with Xero
      </Heading>
      <Flex justifyContent="center" wrap="wrap" mb={6}>
        <Flex maxWidth="280px" direction="column" alignItems="center" p={5}>
          <GatsbyImage
            alt="step1"
            style={imageStyles}
            image={step1.childImageSharp.gatsbyImageData}
          />
          <Heading
            fontSize="1.6em"
            mb={{ base: 0, md: 4 }}
            minH="30px"
            textAlign="center"
          >
            Connecting to Xero is easy
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Setup a connection between Canyou and Xero.
          </Text>
        </Flex>
        <Flex maxWidth="280px" direction="column" alignItems="center" p={5}>
          <GatsbyImage
            alt="step2"
            style={imageStyles}
            image={step2.childImageSharp.gatsbyImageData}
          />
          <Heading
            fontSize="1.6em"
            mb={{ base: 0, md: 4 }}
            minH="30px"
            textAlign="center"
          >
            Collect Employee Info
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Use Canyou&apos;s easy forms and start hiring or create employee
            profiles for your existing staff members.
          </Text>
        </Flex>
        <Flex
          maxWidth="280px"
          direction="column"
          alignItems="center"
          p={5}
          align="center"
        >
          <GatsbyImage
            alt="step3"
            style={imageStyles}
            image={step3.childImageSharp.gatsbyImageData}
          />
          <Heading
            fontSize="1.6em"
            mb={{ base: 0, md: 4 }}
            minH="30px"
            textAlign="center"
          >
            Validate and Sync
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Easily validate information and sync with Xero.
          </Text>
        </Flex>
        <Flex maxWidth="280px" direction="column" alignItems="center" p={5}>
          <GatsbyImage
            alt="step4"
            style={imageStyles}
            image={step4.childImageSharp.gatsbyImageData}
          />
          <Heading
            fontSize="1.6em"
            mb={{ base: 0, md: 4 }}
            minH="30px"
            textAlign="center"
          >
            Keep it all up to date
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Use Canyou to make sure the information is always up to date in
            Xero.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
